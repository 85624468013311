<script>
import GetDetailContenu from '@/components/bibliotheque/getDetailContenu.vue'
import BoutiqueFavorite from '@/mixins/boutiqueFavorite.js'
import RemainingDays from '@/components/shared/remainingDays.vue'

export default {
  name: 'PresentationProduit',
  props: {
    nuage: { required: false, type: Object, default: () => null }
  },
  mixins: [BoutiqueFavorite],
  components: {
    GetDetailContenu,
    RemainingDays
  },
  data () {
    return {
      infoProduit: null,
      nuageItem: null,
      boutiqueItem: null,
      loading: true,
      panel: 0,
      pageNuage: 1,
      panels: [0],
      expand: true,
      isDistribuable: false
    }
  },
  apollo: {
    nuageItem: {
      query: require('@/graphql/queries/v2/nuageItem.gql'),
      variables () {
        return {
          id: this.nuage.id,
          page: this.pageNuage,
          limit: 12
        }
      },
      update (data) {
        return data.nuageItem
      },
      fetchPolicy: 'network-only',
      skip () {
        return !this.nuage || !this.nuage.id
      }
    },
    infoProduit: {
      query: require('@/graphql/queries/plus/graph.gql'),
      variables () {
        return {
          slug: this.$route.query.document
        }
      },
      client: 'plus',
      update (data) {
        this.loading = false
        return data.graph_by_slug
      },
      watchLoading (isLoading) {
        this.loading = isLoading
      },
      skip () {
        return !!this.$route.query.nuage
      }
    },
    boutiqueItem: {
      query: require('@/graphql/queries/v2/boutiqueItemBy.gql'),
      variables () {
        return {
          attrs: { slug: this.$route.query.document }
        }
      },
      update (data) {
        this.loading = false
        return data.boutiqueItemBy
      },
      watchLoading (isLoading) {
        this.loading = isLoading
      },
      skip () {
        return !this.$route.query.nuage
      }
    }
  },
  computed: {
    format () {
      let temp = this.$t('lecture.cahier-numerique')
      if (this.produit && this.produit.boutiqueItem && this.produit.boutiqueItem.formats && this.produit.boutiqueItem.formats.length > 0) {
        temp = this.produit.formats[0].titre
      } else if (this.produit && this.produit.formats && this.produit.formats.length > 0) {
        temp = this.produit.formats[0].titre
      }
      return temp
    },
    mobileTemplate () {
      return this.$store.state.App.mobileTemplate
    },
    profile () {
      return this.$store.state.User.profile
    },
    produit () {
      if (this.infoProduit) {
        return this.infoProduit
      } else if (this.boutiqueItem) {
        return this.boutiqueItem
      } else {
        return null
      }
    },
    description () {
      return this.produit.descriptionNuage ?? this.produit.description
    },
    couverture () {
      if (this.produit && this.produit.image && this.produit.image.medium_image) {
        return { couverture: 'https://plus.dokoma.com' + this.produit.image.medium_image, thumbnail: 'https://plus.dokoma.com' + this.produit.image.thumbnail }
      } else if (this.produit && this.produit.images && this.produit.images.length > 0) {
        const couv = this.produit.images.find(e => e.tagsVisibles.find(t => t.id === 'details-graphiques_image-couverture'))
        if (couv && couv.imageUrl) {
          return { couverture: couv.imageUrl, thumbnail: couv.imageUrl }
        } else {
          return null
        }
      }
      return null
    },
    imagesList () {
      let images = {}
      if (this.produit && this.produit.images && this.produit.images.length) {
        this.produit.images.forEach(img => {
          images.type = 'sku'
          if (img.tagsVisibles && img.tagsVisibles.find(tag => tag.id === 'details-graphiques_image-couverture')) {
            images.coverImage = img
          } else if (img.tagsVisibles && img.tagsVisibles.find(tag => tag.id === 'details-graphiques_image-pictogramme')) {
            images.pictogramme = img
          }
        })
      } else {
        images.type = 'produit'
        if (this.produit.image) {
          images.coverImage = this.produit.image
        }
        if (this.produit.image_picto) {
          images.pictogramme = this.produit.image_picto
        }
      }
      return images
    }
  },
  watch: {
    produit: {
      deep: true,
      immediate: true,
      handler (newVal) {
        if (newVal && newVal.contenus) {
          this.expand = false
        } else {
          this.expand = true
        }
      }
    }
  },
  mounted () {
  },
  methods: {
    addToHisto () {
      this.$store.commit('App/addEntryToHisto', this.produit)
    },
    getFontSize (mot) {
      if (mot.length <= 10) {
        return 2 + 'em'
      } else if (mot.length > 10 && mot.length <= 20) {
        return 2 + 'em'
      } else if (mot.length > 20 && mot.length <= 40) {
        return 2 + 'em'
      } else if (mot.length > 30 && mot.length <= 50) {
        return 2 + 'em'
      } else {
        return 1.75 + 'em'
      }
    },
    async goToContent () {
      const idVar = this.$route.query.id || this.$route.query.document
      let temp = 'https://glossaire.dokoma.com/v2/fr/document/' + idVar
      temp += '?dokUANE=' + this.$store.state.User.profile.info.username + '&access=' + this.$store.state.User.profile.info.accessCode
      window.open(temp, '_blank')
    }
  }
}
</script>

<template lang='pug'>
  .produit-index.split-page(v-if='produit && produit.titre')
    v-sheet.page-header(color='secondary')
      .header-image
        v-img.w-100.h-100(v-if='imagesList && imagesList.type === "sku"', :src='imagesList.coverImage.large_image', :lazy-src='imagesList.coverImage.thumbnail')
        v-img.w-100.h-100(v-else-if='imagesList && imagesList.type === "produit"', :src='imagesList.coverImage.image_type === "Svg" ? imagesList.coverImage.medium_image : "https://plus.dokoma.com" + imagesList.coverImage.large_image', :lazy-src='imagesList.coverImage.image_type === "Svg" ? imagesList.coverImage.medium_image : "https://plus.dokoma.com" + imagesList.coverImage.large_image')
      .header__inner-content
        div.flex.justify-between.items-center.w-100.mb3
          v-btn.white--text(:small='$store.state.App.mobileTemplate', to='/bibliotheque', rounded, color='primary')
            font-awesome-icon.mr2.f4(:icon="['fas', 'arrow-left']")
            span Tous les cahiers
          v-btn.description-button(icon, :color='isFav ? "error" : "light"', @click.prevent='toggleFavBoutique(produit)')
            font-awesome-icon(:icon="[isFav ? 'fas' : 'far', 'heart']", style='font-size: 22px')
        div.flex-grow-1
        template
          v-avatar.mb1.relative(v-if='imagesList && imagesList.pictogramme', size='45', color='transparent')
            div.color-fallback()
            v-img(v-if='imagesList.type === "sku"', :src='imagesList.pictogramme.thumbnail')
            v-img(v-else-if='imagesList.type === "produit"', :src='imagesList.pictogramme.image_type === "Svg" ? imagesList.pictogramme.url_to_image : "https://plus.dokoma.com" + imagesList.pictogramme.url_to_image')
          .header-title()
            div.secondary-title {{format}}
            div.main-title(:style="{ fontSize: getFontSize(produit.titre)}") {{produit.titre}}
            .header__extra-content
              remaining-days(v-if='produit.nuageItem.finAt', :endDate='produit.nuageItem.finAt', context='card')
              v-chip.primary.lighten-5.secondary--text.mt1(small)
                font-awesome-icon.f6.mr2(:icon="['fad', 'file-edit']")
                span {{ $tc('lecture.nombre-page', $store.state.Bibliotheque.nombrePageDocument, {page: $store.state.Bibliotheque.nombrePageDocument}) }}

    .page__main-content.mt3.animated.fadeIn
      section.fullsize-section(v-if='description')
        div.relative.mb3
          v-expand-transition
            v-card.transparent(v-show='expand', height='100%', width='100%', flat)
              div.produit-description(v-html='description')
          v-btn(@click='expand = !expand', text, block, small, rounded ,outlined)
            | {{expand ? 'Masquer la description' : 'Afficher la description'}}
            font-awesome-icon.ml2(:icon="['fas', expand ? 'caret-up' : 'caret-down']", style='font-size: 18px')

      section.large-section.mb4
        .section-subtitle__header(v-if='produit.contenus')
          h2.section-subtitle Contenu
          v-btn(small, outlined, rounded, @click.native="goToContent()") Consulter le sommaire

        v-sheet.section-card(v-if='produit.contenus', style='padding: 0; overflow: hidden;')
          v-list()
            v-expansion-panels(v-model='panels', light)
              template(v-for='(contenu, index) in produit.contenus')
                get-detail-contenu(:contenu='contenu', :nuage='nuage')
        v-btn.mt4(v-else, @click='addToHisto', x-large, color='primary',rounded , dark, :href='"https://glossaire.dokoma.com/dokoma/fr/" + produit.slug', target='_blank', block)
          span.f4.mr3 Consulter
          font-awesome-icon.f3(:icon="['fad', 'eye']")
        template(v-if='isDistribuable')
          .section-subtitle__header.mt4
            h2.section-subtitle Listes de lectures
          v-sheet.section-card.groupes(style='padding: 0; overflow: hidden;', light, :color='$store.state.Preferences.darkMode ? "light" : "white"')
            div.empty-content__wrapper.pa4
              div Vous pouvez désormais partager des pages spécifiques d'un document à vos élèves. Créez une liste de lecture et partagez la directement à vos élèves.
              v-btn.mt3(rounded, color='primary', to='/editeur-lecture') Créer un nouvelle liste
</template>
<style lang='sass'>
  @import 'src/styles/components/bibliotheque/_produit'
  .section-card.groupes
    .v-expansion-panels
      .v-expansion-panel
        &:not(:last-of-type)
          border-bottom: solid 1px white
      .v-expansion-panel-content
        .v-expansion-panel-content__wrap
          padding: 0
  .produit-banner
    .v-image
      .v-responsive__content
        width: unset !important
</style>
