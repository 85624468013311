<script>
import ProduitCard from '@/components/presentation/produitCard.vue'
import ProduitPresentation from '@/components/bibliotheque/produitPresentation.vue'
import transferBiblioListe from '@/components/dialogs/actions/transferBiblioListe.vue'
import ContentHandleList from '@/components/bibliotheque/biblioHandleList.vue'

import { orderBy } from 'lodash-es'

export default {
  name: 'bibliotheque-index',
  components: {
    ProduitCard,
    ProduitPresentation,
    ContentHandleList,
    transferBiblioListe
  },
  data () {
    return {
      search: null,
      listView: false,
      selectedCategorie: { title: 'Tout voir', value: '0' },
      searchTerms: '',
      dialogCode: false,
      selectedList: 0,
      loadingNuage: true,
      nuageItems: [],
      totalNuageItems: 0,
      searchNuage: null,
      filterList: null,
      drag: false,
      searchNuagePage: 1,
      loadingExpiredOrArchived: true,
      expiredOrArchivedItems: [],
      expiredOrArchivedPage: 1,
      searchNuageFiltre: {
        formats: ['config-boutique-types-de-formats-cahiers-numeriques'],
        etiquettes: [
          {
            tags: ['nuage-item-etiquette-archiver'],
            present: false
          }
        ],
        statuts: ['AVANT', 'ACTIF']
      }
    }
  },
  watch: {
    '$store.state.App.mobileTemplate': {
      immediate: true,
      handler (newVal) {
        this.listView = newVal
      }
    },
    '$store.state.App.snackbarGlobalContent': {
      deep: true,
      handler (newVal) {
        this.refetchNuage()
      }
    }
  },
  computed: {
    getNuage () {
      return (this.$route.query.document && this.nuageItems?.find(e => e.boutiqueItem.slug === this.$route.query.document)) ?? null
    },
    getNuageExpire () {
      return (this.$route.query.document && this.expiredOrArchivedItems?.find(e => e.boutiqueItem.slug === this.$route.query.document)) ?? null
    },
    showCreateEvent: {
      get () { return this.$store.state.Dialogs.showCreateEvent },
      set (val) { this.$store.commit('Dialogs/setCreateEvent', val) }
    },
    listesBiblio: {
      get () { return this.$store.state.Bibliotheque.listesBiblio },
      set (val) { this.$store.commit('Bibliotheque/setListesBiblio', val) }
    },
    orderedItems () {
      const order = this.$store.state.Bibliotheque.biblioOrder
      if (order) {
        return orderBy(
          this.nuageItems,
          function (e) {
            return order.includes('date') ? e.ajouteAtPosition : e.boutiqueItem.titre
          },
          order.includes('Up') ? 'asc' : 'desc'
        )
      }
      return this.nuageItems
    },
    searchResultsItems () {
      return this.orderedItems.filter(el => {
        if (this.$store.state.Bibliotheque.showOnlyFavs) {
          const elId = el.boutiqueItem.id
          if (this.$store.state.User.favoris.findIndex(f => f.favori?.id === elId) === -1) {
            return false
          }
        }

        if (!this.searchTerms) {
          return true
        }
        return el.boutiqueItem.titre.toLowerCase().includes(this.searchTerms.toLowerCase())
      })
    }
  },
  apollo: {
    searchNuage: {
      query: require('@/graphql/queries/v2/searchNuageItems.gql'),
      variables () {
        return {
          page: this.searchNuagePage,
          limit: 15,
          q: null,
          filtre: this.searchNuageFiltre,
          tri: [{ sujet: 'AJOUTE_AT', direction: 'DESC' }, { sujet: 'CREATED_AT', direction: 'DESC' }]
        }
      },
      update (data) {
        this.totalNuageItems = data.searchNuageItems.total
        const itemsToAdd = data.searchNuageItems.resultat
          .map((item, index) => {
            return { ajouteAtPosition: (this.searchNuagePage - 1) * 15 + index, ...item }
          })
          .filter(item => {
            return this.nuageItems.findIndex(ni => ni.id === item.id) === -1
          })
        this.nuageItems = [...this.nuageItems, ...itemsToAdd]
        this.getMoreNuage(data.searchNuageItems.resultat?.length === 0)
        this.loadingNuage = false
        return data.searchNuageItems
      },
      fetchPolicy: 'no-cache'
    },
    searchExpiredOrArchivedItems: {
      query: require('@/graphql/queries/v2/searchNuageItemsExpiredOrArchived.gql'),
      variables () {
        return {
          page: this.expiredOrArchivedPage,
          limit: 20,
          inverse: this.searchNuageFiltre,
          filtre: {
            formats: ['config-boutique-types-de-formats-cahiers-numeriques']
          },
          tri: [{ sujet: 'AJOUTE_AT', direction: 'DESC' }, { sujet: 'CREATED_AT', direction: 'DESC' }]
        }
      },
      update (data) {
        this.expiredOrArchivedItems = [...this.expiredOrArchivedItems, ...data.searchNuageItems.resultat]
        this.getMoreExpiredOrArchived(data.searchNuageItems.total, data.searchNuageItems.resultat?.length === 0)
        return data.searchNuageItems
      },
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    refetchNuage () {
      this.loadingNuage = true
      this.nuageItems.splice(0, this.nuageItems.length)
      this.searchNuagePage = 1
      this.$apollo.queries.searchNuage.refetch()
    },
    refetchExpiredOrArchivedItems () {
      this.loadingExpiredOrArchived = true
      this.expiredOrArchivedItems.splice(0, this.expiredOrArchivedItems.length)
      this.expiredOrArchivedPage = 1
      this.$apollo.queries.searchExpiredOrArchivedItems.refetch()
    },
    refetch () {
      // /!\ This is going to do some weird stuff if we trigger refetch before the last fetch ended
      this.refetchNuage()
      this.refetchExpiredOrArchivedItems()
    },
    removeFromArchived (item) {
      let findElem = false
      this.orderedItems.forEach((element) => {
        if (!findElem && element.id === item && element.tagsVisibles && element.tagsVisibles.length > 0) {
          let index = element.tagsVisibles.findIndex(e => e.id === 'nuage-item-etiquette-archiver')
          if (index >= 0) {
            element.tagsVisibles.splice(index, 1)
            findElem = true
          }
        }
      })
    },
    addToArchived (item) {
      this.orderedItems.find(e => e.id === item).tagsVisibles.push({ id: 'nuage-item-etiquette-archiver', titre: 'archive' })
    },
    async getMoreNuage (forceStop = false) {
      if (this.totalNuageItems > this.nuageItems.length > 0 && !forceStop) {
        this.searchNuagePage++
        try {
          await this.$apollo.queries.searchNuage.fetchMore({
            variables: {
              page: this.searchNuagePage,
              limit: 15
            },
            updateQuery (data) {
              console.log(data)
            }
          })
        } catch (err) {}
      } else {
        this.loadingNuage = false
      }
    },
    async getMoreExpiredOrArchived (eoaTotal, forceStop = false) {
      if (eoaTotal > this.expiredOrArchivedItems.length && !forceStop) {
        this.expiredOrArchivedPage += 1
        try {
          await this.$apollo.queries.searchExpiredOrArchivedItems.fetchMore({
            variables: {
              page: this.expiredOrArchivedPage
            },
            updateQuery () {}
          })
        } catch (err) {}
      } else {
        this.loadingExpiredOrArchived = false
      }
    },
    resetQuery () {
      let query = Object.assign({}, this.$route.query)
      delete query.document
      this.$router.replace({ query })
      this.selectedList = null
    },
    toggleListView (val) {
      this.listView = val
    },
    updateSearch (val) {
      this.searchTerms = val
    },
    onIntersect (entries, observer, isIntersecting) {
      // console.log('INTERSECT', entries)
    }
  }
}
</script>

<template lang='pug'>
  .bibliotheque-index.split-page
    div.biblio-wrapper
      content-handle-list(
        origin='bibliotheque'
        :produits='expiredOrArchivedItems'
        @togglelistView='toggleListView'
        @updateSearch='updateSearch'
        @refetch="refetch"
        :loadingExpiredOrArchived="loadingExpiredOrArchived"
      )
      .produits-showcase
        transition(name='custom-classes-transition', enter-active-class='animated faster fadeIn', leave-active-class='animated faster fadeOut', mode='out-in')
          template(v-if='$route.query.document')
            produit-presentation(
              :key='$route.query.document'
              :nuageExpire='getNuageExpire'
              :nuage='getNuage'
              @activate='removeFromArchived'
              @desactivate='addToArchived'
              @refetch="refetch"
            )
          template(v-else)
            div.w-100
              template(v-if='$store.state.Bibliotheque.loadingBiblio || loadingNuage')
                div(v-if='$store.state.App.mobileTemplate')
                  template(v-for='n in 10')
                    v-skeleton-loader.h-100.w-100.mx-auto.loading-card(type='list-item-avatar')
                .produit-cards__wrapper(v-else)
                  template(v-for='n in 10')
                    v-sheet(min-height='325', width='220', rounded, color='transparent')
                      v-skeleton-loader.h-100.w-100.mx-auto.loading-card(type='card', height='325px')
              .produit-cards__wrapper(v-else, :class='{"list-view" : listView}', style='padding-top:0;')
                template(v-if='searchResultsItems && searchResultsItems.length')
                  template(v-if='listView')
                    v-list(rounded, style='padding-top:0; padding-bottom: 0px')
                      template(v-for='(livre, i) in searchResultsItems')
                        v-list-item(:key='i', style='padding:0;', @click='')
                          produit-card.animated.fadeIn(
                            :produit='livre.boutiqueItem ? livre.boutiqueItem : livre'
                            :listView='listView'
                            :debut='livre.debutAt'
                            :finAt='livre.finAt'
                            :nuageItem='livre'
                            @refetch="refetch"
                          )
                        v-divider(v-if='i < searchResultsItems.length - 1')
                        template(v-else, v-intersect='onIntersect')
                  template(v-else)
                    v-lazy(:options="{ threshold: .08 }", :min-height="listView ? '50' : '325'", v-for='(livre, i) in searchResultsItems', :key='i')
                      produit-card.animated.fadeIn(
                        :produit='livre.boutiqueItem ? livre.boutiqueItem : livre'
                        :listView='listView'
                        :debut='livre.debutAt'
                        :finAt='livre.finAt'
                        :nuageItem='livre'
                        @refetch="refetch"
                      )
              template(v-if='(!searchResultsItems || searchResultsItems.length === 0) && !$store.state.Bibliotheque.loadingBiblio && !loadingNuage ')
                div.f4.tc.pa4 {{$t('lecture.aucun-doc')}} {{filterList ? $t('lecture.dans-section') + filterList.titre + '.' : searchTerms ? $t('lecture.correspondant') + searchTerms : '.'}}
</template>
<style lang='sass'>
  @import 'src/styles/pages/_bibliotheque'
  .bibliotheque-index
    .v-skeleton-loader__card
      background-color: white
      height: 325px
      display: flex
      flex-direction: column
      .v-skeleton-loader__card-heading
        flex-grow: 1
    .ghost
      opacity: 0.5
      background: lightgrey
    .list-group
      min-height: 20px
    .list-group-item
      cursor: move
      i
        cursor: pointer
</style>
